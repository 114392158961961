import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Component, ViewContainerRef, Inject, LOCALE_ID, OnInit, AfterViewInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Instituciones } from 'path-shared/models/instituciones';
import { MatDialogRef } from '@angular/material';
import { AlarmaDetallComponent } from './sections/alarmes/components/alarma-detall/alarma-detall.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit, OnInit {
  private static institution: Instituciones;
  title = '';
  onLangChange: Subscription = undefined;
  alarmaRef: MatDialogRef<AlarmaDetallComponent>;
  @Input() get pageTitle() {
    return this.title;
  } set pageTitle(val) {
    timer(100).subscribe(() => this.title = val);
  }


  constructor(
    public toastr: ToastrService,
    vcr: ViewContainerRef,
    private auth: AuthGuardService,
    public translate: TranslateService,
    public notifications: NotificationService,
    @Inject(DOCUMENT) private _document: any,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this._document.documentElement.lang = this.localeId;
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      AppComponent.institution = JSON.parse(inst);
    }
  }
  public static setInstitution(inst) {
    AppComponent.institution = inst;
     sessionStorage.setItem('currentInst', JSON.stringify(inst));
     
     // Limpiamos información de la institucion antigua almacenada en el session storage
     sessionStorage.removeItem('carrersForCombo');
   }

   public static getInstitution() {
     return AppComponent.institution;
   }


   ngOnInit() {


  }
  ngAfterViewInit() {
    this.notifications.configure();
  }

  userLogged() {    
    return this.auth.userLogged();
  }
  
  userLoginCompleted() {
    const usr = sessionStorage.getItem('usuari');
    if (usr === null || usr === undefined) {
      return false;
    } else if (usr === 'null') {
      return false;
    } else {
      return true;
    }
  }
}
