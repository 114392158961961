import { Injectable } from '@angular/core';
import { Usuari } from '../models/usuari.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { PaginationFilter } from '../../../shared/models/pagination.filter';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';

import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';

const defaultSortParam = 'Exp';
const defaultSortDirection = 'desc';

@Injectable()
export class UsuarisService {
  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) {}

  getUsuari(): Observable<any> {    
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlUser'];
      return this.http.get(url, null);
    }
  }

  getUsuariLite(id, password, instit): Observable<any> {
      const url = config.urls['apiUrl'] + api.endpoints['urlUserLite'];
      const params = new HttpParams()
      .append('username', id)
      .append('password', password)
      .append('instit', instit);
      return this.http.get(url, params);
  }

  changePassword(oldPwd, newPwd, userSecureId = null): Observable<any> {

    const url = config.urls['apiUrl'] + api.endpoints['urlUserChangePwd'];
    return this.http.post(url, {oldPwd, newPwd, userSecureId});

  }

  SetEnabled2FA(enabled): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlUserEnable2fa'];
      const params = new HttpParams().append('enabled', enabled);
      return this.http.get(url, params);
    }
  }

  Get2faSecret(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlUserGet2faSecret'];
      return this.http.get(url, null);
    }
  }

  VerifyOtp(secureId, otp): Observable<any> {

    const url = config.urls['apiUrl'] + api.endpoints['urlUserVerify2faOtp'];
    const params = new HttpParams()
    .append('secureId', secureId)
    .append('otp', otp);
    return this.http.get(url, params);

  }

  getCurrentUser() {    
    const usuari = sessionStorage.getItem('usuari');    
    let user: Usuari  = {} as any;
    if (usuari !== null && usuari !== undefined && usuari !== 'null') {
      user = JSON.parse(usuari);
    }
    return user;
  }
  setCurrentUser(usuari: Usuari) {    
    sessionStorage.setItem('usuari', JSON.stringify(usuari));
  }

}
